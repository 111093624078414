import React from "react";
import { graphql, Link } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/blog-page.css'
import { StaticImage } from "gatsby-plugin-image";
import BlogsList from "../components/blogs/blogsList"
// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
import {kebabCase} from 'lodash';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import getOverlappingDaysInIntervals from "date-fns/fp/getOverlappingDaysInIntervals/index.js";
export const query = graphql`
  query BlogPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    blogs: allSanityBlog(
        
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            mainImage {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
              alt
            }
            title
            _rawExcerpt
            slug {
              current
            }
            tags
            publishedAt
          }
        }
      }
  }
`;
const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(0.5),
    textAlign: "center",
    fontSize:"1.4em",
    color: theme.palette.text.secondary
  }
}));

const BlogsPage = props => {
  const { data, errors } = props;
  
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const allTags = [];
  
    // Iterate through each post, putting all found tags into `tags`
    data.blogs.edges.forEach((edge) => {
     console.log(edge.node)
     if(edge.node.tags.length>0){
       console.log(edge.node.tags);
       allTags.push(...edge.node.tags);
     }
    })
    const uniqueTags = [...new Set(allTags)];
   console.log(uniqueTags);
  function GridItem(props) {
    return (
      // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
      // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
      // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
      <Grid item xs={6} sm={12} md={12}>
        <Paper elevation={1} className={props.classes.paper}>{props.children}</Paper>
      </Grid>
    );
  }
   const classes = useStyles();

  const site = (data || {}).site;
  const blogNodes = (data || {}).blogs
    ? mapEdgesToNodes(data.blogs)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className="top-banner">
      
      <StaticImage className="banner" src='./images/7.png' alt="blogs"/>
      <Navbar/>
      </div>
      <div className="column-layout">
      <div className="column-left">
      <div className="page-body-bloglist" >
      <BlogsList nodes={blogNodes} className="blog-list" type="blogs"/>
      </div>
 

      </div>
      <div className="column-right">
        <div className="block-right">
      <h4 className="tag-heading">CATEGORIES</h4>
                 <hr className="tag-line"/>
                
                <Grid  className="category-grid" container spacing={2}>
                {/* {tags.map((tag,index) => (
                    <GridItem key={`tag ` + `index`} classes={classes} >    <Link className="tag-link" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link> </GridItem>
                  
                  ))} */}
                  <GridItem className={"grid-item"}  key={`tag ` + `luxury`} classes={classes} >    <Link className="tag-link" to={`/tags/${kebabCase("luxury")}/`}>{"Luxury"}</Link> </GridItem>
                  <GridItem className={"grid-item"} key={`tag ` + `education`} classes={classes} >    <Link className="tag-link" to={`/tags/${kebabCase("education")}/`}>{"Education"}</Link> </GridItem>
      
       
      </Grid>
      </div>
      <div className="block-right">
      <h4 className="tag-heading">TAGS</h4>
                 <hr className="tag-line"/>

                 <div class="blog-tags">
      <ul>
       {
        
          uniqueTags.map((tag,index) => (
         
            <li key={"tag-" +index}> <Link  to={`/tags/${kebabCase(tag)}/`}>#{tag}</Link></li>
        
        ))
      
        
     
         

       }
       
       
      </ul>
    </div>
    </div>
      </div>
      </div>
      

  
    </Layout>
  );
};

export default BlogsPage;
